<template>
  <PaymentTemplate
    :headerProps="headerProps"
    :mediaInfo="mediaInfo"
    :content="content"
  />
</template>

<script>
import PaymentTemplate from "@/components/templates/Profile/Profile";
import { headerProps } from "@/components/constants";
import heroImg from "@/assets/images/sample-hero.jpg";

export default {
  components: {
    PaymentTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      mediaInfo: [
        {
          image: heroImg,
        },
      ],
    };
  },
  async created() {
  },
};
</script>

<style scoped></style>
