<template>
  <HeaderSection v-bind="headerProps" />
  <SocialBox v-bind="socialProps" />
  <section class="hero-media">
    <Carousel :items="mediaInfo" />
  </section>
  <div class="content-wrap">
    <div class="entry entry-page">
      <h2 class="entry-title">Profile</h2>
      <div class="entry-content">
        <Profile @onSave="onSaveDetail" />
      </div>
    </div>
    <CopyRight v-bind="footerProps" />
  </div>
</template>

<script>
import HeaderSection from "../../organisms/Header/Header.vue";
import SocialBox from "../../molecules/SocialBox/SocialBox.vue";
import CopyRight from "../../molecules/CopyRight/CopyRight.vue";
import Profile from "../../organisms/Profiles/Profiles.vue";
import { footerProps } from "../../constants";
import Carousel from "../../organisms/Carousel/Carousel.vue";

/**
 *
 */
export default {
  components: {
    HeaderSection,
    SocialBox,
    CopyRight,
    Profile,
    Carousel,
  },
  props: {
    headerProps: {
      type: Object,
      default: () => null,
    },
    socialProps: {
      type: Object,
      default: () => null,
    },
    title: {
      type: String,
      default: () => "",
    },
    mediaInfo: {
      type: Object,
      default: () => null,
    },
    blogs: {
      type: Array,
      default: () => [],
    },
    pageInfo: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      footerProps,
    };
  },
  methods: {
    async onSaveDetail(form) {
      try {
        const data = {
          username: form.handle,
          bio: form?.desc,
          firstName: form?.firstName,
          lastName: form?.lastName,
        };
        if (form?.company && this.state.currentUser?.company?.id) {
          data.company = {
            update: {
              name: form?.company,
              username: form?.company?.toLowerCase(),
              url: form?.url,
            },
          };
        } else if (form?.company) {
          data.company = {
            create: {
              name: form?.company,
              username: form?.company?.toLowerCase(),
              url: form?.url,
            },
          };
        }
        await this.actions.user.updateUser({
          where: { id: this.state.currentUser?.id },
          data,
        });
        this.actions.alert.showSuccess({
          message: "Updated user profile successfully",
        });
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
  },
};
</script>
